@font-face {
  font-family: "Aeonik-Regular";
  src: url(https://cdn.gravito.net/fonts/Aeonik-Regular.woff2) format("woff");
}
@font-face {
  font-family: "Aeonik-Medium";
  src: local("Aeonik-Medium"),
    url(https://cdn.gravito.net/fonts/Aeonik-Medium.woff2) format("woff");
}

body {
  margin: 0;
  font-family: "Aeonik-Regular", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
